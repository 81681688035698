import { useEffect } from 'react';
import { Badge, Tabs } from 'antd';
import { translateX } from 'utils/helpers';
import {
  ApiOutlined,
  AppstoreAddOutlined,
  CreditCardOutlined,
  DatabaseOutlined,
  TeamOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import app from 'configs/ConfigIndex';
import AccountOrganizationsList from './organizationsTab/List/ListIndex';
import appService from 'services/appService';
import AccountWhatsAppList from 'modules/app-views/shared-modules/account/whatsappTab/List/ListIndex';
import moduleService from 'components/jarvisly-module/ModuleService';
import ProfileTab from './profileTab/ProfileTab';
import IntegrationLogsTab from './integrationLogsTab/IntegrationLogsTab';
import useTabParent from 'components/jarvisly-components/hooks/useTabParentHook';
import { rdxSetParameters } from 'store/slices/moduleSlice';
import { useDispatch, useSelector } from 'react-redux';
import TeamTab from './teamTab/TeamTab';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const privateTabs = ['team', 'billings'];
if (app.ENABLE_MARKETPLACE) privateTabs.push('resources');
if (app.WHATSAPP_INTEGRATED) privateTabs.push('whatsapp');

const publicTabs = ['profile', 'organizations'];
if (app.API_INTEGRATED) publicTabs.push('integration');

// COMPONENT *******************************************************************
// *****************************************************************************

const AccountIndex = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  let { selectedModule } = props;

  // local variables -----------------------------------------------------------
  const { selectedSubscription } = useSelector(s => s.authSlice);
  const initTab = selectedModule?.documentState?.initTab || 'profile';
  const dispatch = useDispatch();

  // component states ----------------------------------------------------------
  const [tab, setTab] = useTabParent([...privateTabs, ...publicTabs], 'name');

  // local variables II --------------------------------------------------------
  // -3: carrinho e demo (vencido), 1: carrinho, 3: carrinho e demo (vigente)
  const shopCartCounter = selectedSubscription?.marketplace?.filter(m =>
    [-3, 1, 3].includes(m.status),
  ).length;

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    appService.initRoute({
      moduleType: null,
      showPageHeader: false,
      showBreadcrumb: false,
      disableSubscriptionCombo: false,
    });

    (async () => {
      const [doc] = await moduleService.getParameters(true);

      if (selectedModule?.methods?.decorateParameters && doc) {
        selectedModule.methods.decorateParameters(doc);
      }

      dispatch(rdxSetParameters(doc));
    })();

    setTimeout(() => moduleService.loadingModule(false), 200);

    if (!selectedSubscription) return;

    // check selected tab
    const idx = tabItems
      ? tabItems?.findIndex(x => x.key === tab && !x.hide)
      : -1;

    if (idx === -1) setTab(initTab);
  }, [selectedSubscription]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;

    setTab(value);
  };

  // local variables III -------------------------------------------------------
  const tabItems = buildTabItems();

  // UI COMPONENT --------------------------------------------------------------
  return (
    <div className="card-container">
      <Tabs
        type="line"
        tabPosition="top"
        activeKey={tab}
        items={tabItems ? tabItems?.filter(x => !x.hide) : []}
        onTabClick={onTabClick}
      />
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItems() {
    return [
      {
        key: 'profile',
        hide: false,
        label: (
          <>
            <UserOutlined />
            <span>{translateX('my_profile')}</span>
          </>
        ),
        // children: (<AccountProfile/>),
        // children: <h1>PROFILE</h1>,
        children: <ProfileTab {...props} activeTab={tab} />,
      },

      {
        key: 'organizations',
        hide: false,
        label: (
          <>
            <DatabaseOutlined />
            <span>{translateX(app.PROFILE.TITLE_ORGANIZATIONS_TAP)}</span>
          </>
        ),
        children: <AccountOrganizationsList {...props} activeTab={tab} />,
        // children: <div>Organizations List</div>,
        // children: <ListIndex {...props} />,
      },

      {
        key: 'resources',
        hide:
          selectedSubscription?.profile !== 'owner' || !app.ENABLE_MARKETPLACE,
        label: (
          <>
            <AppstoreAddOutlined />
            <span>{translateX('resources')}</span>
          </>
        ),
        // children: (<AccountMarketplace/>),
        children: <div>Resources</div>,
      },

      {
        key: 'team',
        hide: selectedSubscription?.profile !== 'owner',
        label: (
          <>
            <TeamOutlined />
            <span>{translateX('team')}</span>
          </>
        ),
        children: <TeamTab {...props} activeTab={tab} />,
      },

      {
        key: 'billings',
        hide: selectedSubscription?.profile !== 'owner',
        label: (
          <Badge count={shopCartCounter}>
            <CreditCardOutlined />
            <span className="mr-2">{translateX('billings')}</span>
          </Badge>
        ),
        children: <div>Billing</div>,
        // children: <AccountBillings />,
      },

      {
        key: 'whatsapp',
        hide:
          !app.WHATSAPP_INTEGRATED ||
          !selectedSubscription?._id ||
          selectedSubscription?.profile === 'guest',
        label: (
          <>
            <WhatsAppOutlined />
            <span>{translateX('whatsapp')}</span>
          </>
        ),
        children: <AccountWhatsAppList {...props} activeTab={tab} />,
      },

      {
        key: 'integration',
        hide: !app.API_INTEGRATED || !selectedSubscription?._id,
        label: (
          <>
            <ApiOutlined />
            <span>{translateX('integration')}</span>
          </>
        ),
        children: <IntegrationLogsTab {...props} activeTab={tab} />,
      },
    ];
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default AccountIndex;
