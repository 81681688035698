import { getFromLocalStorage } from 'utils/helpers';
import IotDevicesList from './List/List';
import IotDevicesParametersModal from './Parameters/ParametersModal';
import IotDevicesForm from './Form/Form';
import { DefaultFormHeaderColumn1 } from 'components/jarvisly-module/Form/FormHeader';
import {
  IotDevicesFormHeaderColumn2,
  IotDevicesFormHeaderColumn3,
  IotDevicesFormHeaderTags,
  IotDevicesFormHeaderTitle,
  IotDevicesHeaderCustomIcons,
} from './Components';
import dayjs from 'dayjs';
import IotDevicesDashboardWaterLevel from './Dashboard/WaterLevel';
import IotDevicesFilter from './List/Filter';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MODULE_ID = 'appveerticalIotDevices';
const MODULE_API = '/v1/iot-device';

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************
const APPVEERTICAL_IOTDEVICES_MODULE = {
  id: MODULE_ID,
  noBlockScreenWithAppNotConfigured: false,
  api: MODULE_API,
  urlCustomForm: null,
  defaultUrlQueryString: null,

  components: {
    // list
    dashboard: props => <IotDevicesDashboardWaterLevel {...props} />,
    list: props => <IotDevicesList {...props} />,
    grid: null,
    kanban: null,
    filter: props => <IotDevicesFilter {...props} />,

    // form
    form: props => <IotDevicesForm {...props} />,
    formHeaderTitle: props => <IotDevicesFormHeaderTitle {...props} />,
    formHeaderSubtitle: null,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: props => <IotDevicesFormHeaderColumn2 {...props} />,
    formHeaderColumn3: props => <IotDevicesFormHeaderColumn3 {...props} />,
    formHeaderTags: props => <IotDevicesFormHeaderTags {...props} />,
    formHeaderCustomIcons: props => <IotDevicesHeaderCustomIcons {...props} />,

    // module parameters
    parameters: props => <IotDevicesParametersModal {...props} />,

    // module data import
    dataImport: null,

    // data preview
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
    // canEditDocument
    // decorateParameters,
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {},
    filterOptions: {
      status: [
        // 'not_updated',
        // 'pending_update',
        // 'waiting_for_update',
        // 'updated',
        'online',
        'offline',
        'calibrating',
      ],
      condition: ['new', 'assembled', 'installed', 'removed', 'discarded'],
      type: ['temperature_sensor', 'water_level_sensor'],
      category: ['school', 'company', 'hospital'],
    },

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      lastReadAt: -1,
    },

    sorterOptions: [
      'title',
      'lastReadAt',
      '_metadata.counter',
      '_metadata.audit.updatedAt',
      'status',
      'condition',
      'channel',
      'type',
      'location',
      'sector',
    ],

    search: {},

    textSearch: {},
  },

  listState: {
    override: false,
    hideBreadcrumb: false,

    showParameters: false,
    showDataImport: false,

    viewMode: ['list', 'dashboard'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
    fieldsForRegex: ['title'],
    textSearchInDrawer: true, // inform that the textSearch field is into Drawer Filter to mark the filter button as danger (red)
    refreshDataOfList: 10000,
  },

  documentState: {
    showParameters: true,

    disableLockForEdit: false,
    initTab: 'device',
    fieldName: 'name',
    newDocumentTitle: 'new_device',
    autoFill: false,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'calibrate',
      'change_condition',
    ],
    refreshParametersOnSave: true,
    indexes: ['title', 'model', '__customer.name'],

    refreshDataOfDocument: 60000,
    mockDocument: {},
  },
};

// EXPORT ********************************************************************
// ***************************************************************************
export default APPVEERTICAL_IOTDEVICES_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  if (!doc) return doc;

  const formatDate = date =>
    dayjs(date).isValid() ? dayjs(date).format('L') : null;

  const dateFields = [
    { path: 'lastReadAt' },
    { path: 'firstReadAt' },
    { path: '__customer.birthdate', format: true },
    { path: '__partner.birthdate', format: true },
    { path: 'field1.minReachedReadAt', format: true },
    { path: 'field1.maxReachedReadAt', format: true },
    { path: 'activatedAt', format: true },
    { path: 'calibratingStartedAt', format: true },
    { path: 'calibratingFinishedAt', format: true },
    { path: 'historic.readAt', format: true },
  ];

  dateFields.forEach(({ path, format }) => {
    const keys = path.split('.');
    let target = doc;

    // Traverse nested fields
    for (let i = 0; i < keys.length - 1; i++) {
      target = target[keys[i]];
      if (!target) return; // Exit if the path does not exist
    }

    const field = keys[keys.length - 1];
    if (target[field]) {
      target[field] = format ? formatDate(target[field]) : dayjs(target[field]);
    }
  });

  return doc;
}
