import { useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { ModuleContext } from '../../jarvisly-module/ModuleContext';
import useUrl from './urlHooks';
import { useSelector } from 'react-redux';

const useTabParent = (availableTabs, fieldFocus, initialTab) => {
  // providers context ---------------------------------------------------------
  const { selectedModule, setModuleFocus, _id } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const { moduleType } = useSelector(s => s.moduleSlice);
  const [searchParams] = useSearchParams();
  const initTab =
    moduleType === 'list'
      ? selectedModule?.listState?.initTab || initialTab
      : selectedModule?.documentState?.initTab || initialTab;
  const url = useUrl();

  // methods -------------------------------------------------------------------

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);

    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab, field: fieldFocus }));
    // }, [tab, _id, JarvislyFormContext]); // eslint-disable-line react-hooks/exhaustive-deps
  }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  return [tab, setTab];
};

export default useTabParent;
