import useTabChild from 'components/jarvisly-components/hooks/useTabChild';

// COMPONENT *******************************************************************
// *****************************************************************************
const TeamTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  useTabChild('team', null, props);

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return <>THIS IS THE TEAM TAB</>;

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default TeamTab;
