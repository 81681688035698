import { getFromLocalStorage } from 'utils/helpers';
import ReceivablesList from './List/List';
import ReceivablesForm from './Form/Form';
import dayjs from 'dayjs';
import {
  canEditDocument,
  ReceivablesFormHeaderColumn1,
  ReceivablesFormHeaderColumn2,
  ReceivablesFormHeaderColumn3,
  ReceivablesFormHeaderSubtitle,
  ReceivablesFormHeaderTags,
  ReceivablesFormHeaderTitle,
} from './Components';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MODULE_ID = 'applekkusReceivables';
export const RECEIVABLES_API = `/v1/receivables`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const MULTI_RECEIVABLES_MODULE = {
  id: MODULE_ID,
  noBlockScreenWithAppNotConfigured: false,
  api: RECEIVABLES_API,
  urlCustomForm: null,
  defaultUrlQueryString: null,

  components: {
    // list
    dashboard: null,
    list: props => <ReceivablesList {...props} />,
    grid: null,
    kanban: null,
    filter: null,

    // form
    form: (props, ref) => <ReceivablesForm {...props} ref={ref} />,
    formHeaderTitle: props => <ReceivablesFormHeaderTitle {...props} />,
    formHeaderSubtitle: props => <ReceivablesFormHeaderSubtitle {...props} />,
    formHeaderColumn1: props => <ReceivablesFormHeaderColumn1 {...props} />,
    formHeaderColumn2: props => <ReceivablesFormHeaderColumn2 {...props} />,
    formHeaderColumn3: props => <ReceivablesFormHeaderColumn3 {...props} />,
    formHeaderTags: props => <ReceivablesFormHeaderTags {...props} />,
    formHeaderCustomIcons: null,

    // module parameters
    parameters: null,

    // module data import
    dataImport: null,

    // data preview
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
    canEditDocument,
    // decorateParameters,
  },

  initialRequest: {
    folder: 'documents',

    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {},

    filterOptions: {
      status: [
        'new',
        'in_analysis',
        'waiting_for_payment',
        'paid',
        'received',
        'overdue',
        'cancelled',
        'cancelled_by_customer',
      ],
      paymentMethod: ['credit_card', 'pix'],
    },

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },

    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      '_metadata.audit.updatedAt': -1,
    },

    sorterOptions: [
      'name',
      '_metadata.audit.updatedAt',
      'dueDate',
      '_metadata.counter',
      'status',
    ],

    search: {},

    textSearch: {},
  },

  listState: {
    override: false,
    hideBreadcrumb: false,

    showParameters: false,
    showDataImport: false,

    initTab: null,
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
    fieldsForRegex: [],
    textSearchInDrawer: true, // inform that the textSearch field is into Drawer Filter to mark the filter button as danger (red)
    refreshDataOfList: 0,
  },

  documentState: {
    showParameters: false,

    disableLockForEdit: false,
    initTab: 'record',
    fieldName: 'name',
    newDocumentTitle: 'new_charge',
    initialState: {
      status: 'new',
    },
    autoFill: false,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      // 'divider',
      // 'document_status',
    ],
    refreshParametersOnSave: true,
    indexes: [],

    refreshDataOfDocument: 0,
    mockDocument: {
      selectedBank: '46100011135989',
      value: 430,
      dueDate: dayjs('2024-03-10T03:00:00.000Z'),
      billingType: 'slip_pix',
      description: 'Instalação X',
      installment: 1,
      valueSuffix: 'yearly',
      customer: {
        customerType: 'registered_customer',
        selectedEntity: {
          key: '65d01606f2285de2aab15db8',
          value: 'Ricardo Biruel',
          label: 'Ricardo Biruel',
        },
        cpfCnpj: '28100151806',
        cpfCnpjNewCustomer: null,
        name: null,
        mobile: '19982320000',
        billingEmails: ['biruel@nfodesk.com.br'],
        phone: '19982320000',
        email: 'biruel@nfodesk.com.br',
        zip: '13060173',
        address1: 'Rua José Martins Dias',
        number: '720',
        address2: 'Sala 10',
        neighborhood: 'Jardim Novo Campos Elíseos',
        __city: 'Campinas - SP',
        note: 'Proximo Estação Y',
      },
    },
  },
};

// EXPORT ********************************************************************
// ***************************************************************************

export default MULTI_RECEIVABLES_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  if (doc?.discountExpireDate) {
    doc.discountExpireDate = dayjs(doc.discountExpireDate);
  }

  if (doc?.dueDate) doc.dueDate = dayjs(doc.dueDate);

  return doc;
}
