import {
  AppstoreOutlined,
  EditOutlined,
  LineChartOutlined,
  ShoppingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import {
  LiaBoxesSolid,
  LiaFileContractSolid,
  LiaShippingFastSolid,
} from 'react-icons/lia';
import { GoTools } from 'react-icons/go';
import {
  RiContrastDrop2Line,
  RiCoupon3Line,
  RiDiscountPercentLine,
  RiRefund2Line,
} from 'react-icons/ri';
import {
  LuBriefcaseBusiness,
  LuThermometerSnowflake,
  LuWarehouse,
  LuWrench,
} from 'react-icons/lu';
import { BiWater } from 'react-icons/bi';
import {
  PiHandCoinsLight,
  PiInvoiceLight,
  PiMoney,
  PiToolboxDuotone,
  PiTruck,
} from 'react-icons/pi';
import { FaRegHandshake } from 'react-icons/fa6';
import { BsBoxSeam, BsPersonGear, BsPersonVideo2 } from 'react-icons/bs';
import {
  MdOutlineAccountBalance,
  MdOutlineAccountTree,
  MdOutlineEvent,
  MdOutlineFactory,
  MdOutlineHomeRepairService,
  MdOutlinePrecisionManufacturing,
  MdOutlineProductionQuantityLimits,
  MdOutlineShoppingCart,
  MdOutlineWarehouse,
  MdShoppingCartCheckout,
} from 'react-icons/md';
import {
  FaBarcode,
  FaChartBar,
  FaRegCalendarCheck,
  FaRegClipboard,
  FaRegEnvelope,
  FaRegIdBadge,
} from 'react-icons/fa';
import {
  IoCalculatorOutline,
  IoDocumentOutline,
  IoDocumentTextOutline,
  IoReceiptOutline,
} from 'react-icons/io5';
import {
  HiOutlineBuildingOffice2,
  HiOutlineCalculator,
  HiOutlineDocumentArrowDown,
  HiOutlineDocumentArrowUp,
  HiOutlineRocketLaunch,
} from 'react-icons/hi2';
import { FiShoppingBag } from 'react-icons/fi';
import { VscTools } from 'react-icons/vsc';
import { HiOutlineDocumentSearch } from 'react-icons/hi';
import {
  TbBuildingWarehouse,
  TbForklift,
  TbMoneybag,
  TbSquarePercentage,
} from 'react-icons/tb';
import { GrCertificate, GrMoney } from 'react-icons/gr';
import { GiGearHammer } from 'react-icons/gi';

const APP_VEERTICAL = {
  ID: 'appveertical',
  NAME: 'Veertical IoT',
  THEME_COLOR: 'CUSTOM_VEERTICAL_APP',
  THEME: 'light',
  FINTECH_SERVICES: false,
  DOMAINS: ['sistema.veertical.com', 'sistema.veertical.com.br'],
  '//PROFILE': [
    'singleCompany',
    'multiCompanies',
    'singleClinic',
    'multiClinics',
    'condominium',
  ],
  PROFILE: 'singleCompany',
  DEV_MODE: false,
  COPY_RIGHT: 'Veertical Technologies',
  '//API_DEV_HOST': ['localhost', 'vps', 'heroku'],
  API_DEV_HOST: 'localhost',
  API_DEV_HTTPS: false,
  API_PORT_DEV: 8005,
  API_PORT_PRODUCTION: 8005,
  WS_PORT: 9001,
  API_HOST_DEV_LOCALHOST: 'localhost',
  API_HOST_DEV_VPS: 'dev.jarvisly.com',
  API_HOST_PRODUCTION: 'vps.jarvisly.com',
  API_HOST_DEV_HEROKU: 'veertical.herokuapp.com',
  WHATSAPP_INTEGRATED: true,
  TOKEN: process.env.REACT_APP_APPVEERTICAL_TOKEN,
  LOCALE: 'pt',

  LOGO_APP: '/apps/appveertical/logo-app.png',
  LOGO_APP_WHITE: '/apps/appveertical/logo-app-white.png',

  LOGO_APP_ICON: '/apps/appveertical/logo-app-icon.png',
  LOGO_APP_ICON_WHITE: '/apps/appveertical/logo-app-icon-white.png',

  LOGO: '/apps/appveertical/logo.png',
  LOGO_WHITE: '/apps/appveertical/logo-white.png',
  LOGO_LOGIN_SIDE: '/apps/appveertical/logo-app-side.png',

  TITLE: 'Veertical IoT',
  '//SLOGAN': 'Condomínios Inteligentes',
  SLOGAN: '',
  SLOGAN2: '',
  SLOGAN_REGISTRATION: 'registration_slogan',
  SLOGAN_REGISTRATION2: '',
  APPLICATION_LAYER: 'adm',
  LOGIN_IDENTIFICATION: 'email',
  SELF_REGISTRATION: false,
  GOOGLE_LOGIN: false,
  FACEBOOK_LOGIN: false,
  RESET_PASSWORD: true,
  NAVIGATION: {
    TYPE: 'MENU',
    CONFIGURATIONS: false,
    LANGUAGES: true,
  },
  ENABLE_MARKETPLACE: false,
  SHOW_ALL_MENUS: false,
  MENU_NAVIGATION: [
    // ******************
    // MONITORING
    // ******************
    {
      key: 'monitoring',
      path: '/app/monitoring',
      title: 'monitoring',
      icon: AppstoreOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'monitoring_water_level',
          path: '/app/monitoring/water_level',
          title: 'water_level',
          icon: BiWater,
          moduleId: 'appveerticalIotDevices',
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'monitoring_temperature',
          path: '/app/monitoring/temperature',
          title: 'temperature',
          icon: LuThermometerSnowflake,
          moduleId: null,
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'monitoring_humidity',
          path: '/app/monitoring/humidity',
          title: 'humidity',
          icon: RiContrastDrop2Line,
          moduleId: null,
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
      ],
    },

    // ******************
    // RECORDS
    // ******************
    {
      key: 'records',
      path: '/app/records',
      title: 'records',
      className: '',
      icon: EditOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        // entities
        {
          key: 'records_entities',
          path: '/app/records/entities',
          title: 'entities',
          icon: TeamOutlined,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'records_entities_providers',
              path: '/app/records/entities/providers',
              title: 'providers',
              icon: FaRegHandshake,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },

            {
              key: 'records_entities_installers',
              path: '/app/records/entities/installers',
              title: 'installers',
              icon: BsPersonGear,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },

            {
              key: 'records_entities_consultants',
              path: '/app/records/entities/consultants',
              title: 'consultants',
              icon: LuBriefcaseBusiness,
              moduleId: 'applekkusConsultants',
              component: null,
              marketplace: true,
              subscriptionLevel: [0, 1],
            },

            {
              key: 'records_entities_customers',
              path: '/app/records/entities/customers',
              title: 'customers',
              icon: TeamOutlined,
              moduleId: 'applekkusCustomer',
              component: null,
              marketplace: true,
              subscriptionLevel: [0, 1],
            },

            {
              key: 'records_entities_contacts',
              path: '/app/records/entities/contacts',
              title: 'contacts_list',
              icon: BsPersonVideo2,
              moduleId: 'applekkusContacts',
              component: null,
              marketplace: true,
              subscriptionLevel: [0, 1],
            },
          ],
        },

        // services and products
        {
          key: 'records_stock',
          path: '/app/records/stock',
          title: 'stock',
          icon: MdOutlineWarehouse,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'records_stock_services',
              path: '/app/records/stock/services',
              title: 'services',
              icon: LuWrench,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },

            {
              key: 'records_stock_products',
              path: '/app/records/stock/products',
              title: 'products',
              icon: BsBoxSeam,
              moduleId: 'applekkusProducts',
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
          ],
        },
      ],
    },

    // ******************
    // ADMIN
    // ******************
    {
      key: 'admin',
      path: '/app/admin',
      title: 'administrative',
      className: '',
      icon: MdOutlineAccountTree,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        // ------------------
        // COMMERCIAL
        // ------------------
        {
          key: 'admin_commercial',
          path: '/app/admin/commercial',
          title: 'commercial',
          className: '',
          icon: FaRegHandshake,
          isGroupTitle: false,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'admin_commercial_crm',
              path: '/app/admin/commercial/crm',
              title: 'crm',
              icon: FaChartBar,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_commercial_quotations',
              path: '/app/admin/commercial/quotations',
              title: 'quotations',
              icon: IoCalculatorOutline,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_commercial_orders',
              path: '/app/admin/commercial/orders',
              title: 'orders',
              icon: ShoppingOutlined,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_commercial_contracts',
              path: '/app/admin/commercial/contracts',
              title: 'contracts',
              icon: GrCertificate,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
          ],
        },

        // ------------------
        // OPERATIONAL
        // ------------------
        {
          key: 'admin_operational',
          path: '/app/admin/operational',
          title: 'operational',
          icon: GiGearHammer,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'admin_operational_services',
              path: '/app/admin/operational/services',
              title: 'service_orders',
              icon: MdOutlineHomeRepairService,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_operational_activations',
              path: '/app/admin/operational/activations',
              title: 'activations',
              icon: HiOutlineRocketLaunch,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
          ],
        },

        // ------------------
        // FINANCIAL
        // ------------------
        {
          key: 'admin_financial',
          path: '/app/admin/financial',
          title: 'financial',
          className: '',
          icon: GrMoney,
          isGroupTitle: false,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'admin_financial_nfse_emissions',
              path: '/app/admin/financial/nfse',
              title: 'nfse_emission',
              icon: IoDocumentOutline,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },

            {
              key: 'admin_financial_receivable',
              path: '/app/admin/financial/receivable',
              title: 'receivable',
              icon: TbMoneybag,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_financial_commissions',
              path: '/app/admin/financial/commissions',
              title: 'commissions',
              icon: TbSquarePercentage,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_financial_payable',
              path: '/app/admin/financial/payable',
              title: 'payable',
              icon: PiHandCoinsLight,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_financial_bank_reconciliation',
              path: '/app/admin/financial/bank_reconciliation',
              title: 'bank_reconciliation',
              icon: MdOutlineAccountBalance,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
          ],
        },
      ],
    },
  ],
  FIREBASE_CONFIG: JSON.parse(
    process.env.REACT_APP_APPVEERTICAL_FIREBASE_CONFIG,
  ),
};

export default APP_VEERTICAL;
