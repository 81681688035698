import {
  getFieldName,
  goFocus,
  JarvislyAsyncAutoComplete,
  JarvislyCnpj,
  JarvislyCpf,
  JarvislyCpfCnpj,
  JarvislyDatePicker,
  JarvislyEmail,
  JarvislyFormContext,
  JarvislyInput,
  JarvislyPhone,
  JarvislyRadioButtons,
  JarvislySegmented,
  JarvislySelect,
  JarvislySelectGender,
  JarvislyTitle,
  JarvislyWebsite,
  setField,
} from 'components/jarvisly-components/others/FormComponents';
import appService from 'services/appService';
import entityService from 'services/entityService';
import {
  clone,
  isValidCnpj,
  isValidCpf,
  isValidCpfCnpj,
  isValidEmail,
  isValidPhoneNumber,
  returnOnlyNumbers,
  translateX,
} from 'utils/helpers';
import { Badge, Button, Col, Collapse, Flex, Row, Tooltip } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { memo, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { EditOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { ModuleContext } from '../../../jarvisly-module/ModuleContext';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import JarvislyContactForm from './JarvislyContactForm';
import JarvislyAddressForm from './JarvislyAddressForm';

/*
// Variável para armazenar o último campo que recebeu o foco
let lastFocusedElement = null;

// Evento para capturar o foco em qualquer elemento dentro do body
document.body.addEventListener('focusin', event => {
  const focusedElement = event.target; // Elemento que recebeu o foco
  console.log(`Foco no elemento: ${focusedElement.id}`);
  lastFocusedElement = focusedElement; // Armazena o último elemento focado
});

// Evento para capturar quando um campo perde o foco
document.body.addEventListener('focusout', event => {
  const blurredElement = event.target; // Elemento que perdeu o foco
  console.log(`Elemento perdeu o foco: ${blurredElement.id}`);

  // Usando `event.relatedTarget` para saber para onde o foco foi
  if (event.relatedTarget) {
    console.log(`Foco foi para: ${event.relatedTarget.id}`);
  }
});
*/

// COMPONENT *******************************************************************
// *****************************************************************************
const JarvislyEntityForm = memo(props => {
  // providers context ---------------------------------------------------------
  const { elRefs, form, requiredFields } = useContext(JarvislyFormContext);
  const { refreshDocument } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  const {
    context,
    showTitle,
    showWebsite = false,
    entityPersonType = 'manual', // 'auto', 'manual', 'company', 'person'
    entityPersonDefault = ['person', 'company']?.includes(
      props?.entityPersonType,
    )
      ? props.entityPersonType
      : 'person', // 'person' or 'company'
    additionalInfo = 'none', // 'optional' (collapsed), 'required' (explicit) or 'none' (hidden)
    document,

    selectable = false,
    selectedEntity,
    setSelectedEntity,
    mode,

    originRegisteredLabel = 'registered_entity',
    originNewLabel = 'new_entity',
    searchEntityLabel = 'search',

    showCpfCnpj = true,
    cpfCnpjFirst = true,
    setShowBusinessAreaModal,
    setSelectedRecord,
    // setRequiredFields,

    setEntityType,
    entityType = props?.entityPersonType || 'person',
    completeDataProfile = props?.completeDataProfile
      ? props?.completeDataProfile
      : ['customer', 'provider', 'collaborator', 'resident', 'operator'],

    setLoading,
  } = props;

  // local variables I ---------------------------------------------------------
  const isMobile = appService.isMobile('sm');
  const segmentedOptions = buildSegmentedOptions();
  const { selectedModule, parameters } = useSelector(s => s.moduleSlice);
  const navigate = useNavigate();
  const phoneOrEmailRequired = requiredFields?.some(
    field => field === 'phoneEmail' || field === `${context}.phoneEmail`,
  );
  // const moduleParameters = parameters?.crmParameters || {};

  // component states ----------------------------------------------------------
  const [, setBillingEmailsOptions] = useState([]);
  const [entityOrigin, setEntityOrigin] = useState(
    selectable ? 'registered_entity' : 'new_entity',
  );
  const [isPhoneRequired, setIsPhoneRequired] = useState(false);
  const [isEmailRequired, setIsEmailRequired] = useState(false);
  // const [focusApplied, setFocusApplied] = useState(null);

  // local variables II --------------------------------------------------------
  const cpfCnpjLabel = buildCpfCnpjLabel();

  // hooks ---------------------------------------------------------------------

  // AO HABILITAR OS HOOKS ABAIXO DEVEMOS INFORMAR RETURN !SELECTABLE SE NECESSÁRIO PARA NAO RODAR O HOOKS
  // ALGUMAS TELAS PRECISA DESSES HOOKS E OUTRAS NÃO
  // CONSIDERAR OS TIPOS DE TELAS ABAIXO
  /*
   * 1) SOMENTE DADOS DO CLIENTE COMO PRINCIPAL DOCUMENTO (SEM CONTEXTO)
   * 2) DADOS DO CLIENTE SENDO UM CONTEXTO ADICIONAR (EX: IOT DEVICES - O DOCUMENTO É O DEVICE E NA TAB CUSTOMER TEMOS OS DADOS DO CLIENTE (CONTEXT __CUSTOMER)
   * 3) MESMO QUE O ITEM 2 MAS COM MAIS CONTEXTOS, EXEMPLO: ALÉM DA TAB CUSTOMER, PODEMOS TER A TAB PARTNER COM DADOS DO PARCEIRO (CONTEXT: __PARTNER)
   * 4) DADOS DO CLIENTE SENDO UM CONTEXT PORÉM ÚNICO, NÃO PODENDO SER ALTERADO QUANDO O DOCUMENTO ESTIVER SALVO, EXEMPLO: CRM -> OS DADOS SAO REFERENTE AO CLIENTE MAS O DOCUMENTO PRINCIPAL FICARA NO CRM
   * 5) SEMPRE ATENTO AS OPÇÕES SELECTABLE OU NÃO
   * 6) AINDA FALTA CORRIGIR O TIPO: "AUTO" PARA DEFINIR PERSON OU COMPANY DURANTE A DIGITAÇÃO DO DOCUEMNTO
   * 7) AINDA FALTA CORRIGIR OS TIPOS DE FORM (SIMPLES), CRM -> NÃO PRECISANDO DO DOCUMENTO NO INICIO
   * 8) AINDA FALTA CORRIGIR OS TIPOS DE CPFCNPJ-FIRST PARA MOSTRAR O CAMPO DE DOCUMENTOS ANTES OU DEPOIS DO NOME
   * */

  // novo A
  useLayoutEffect(() => {
    const type =
      document?.[context]?.dataType ||
      document?.[getFieldName('dataType', context)] ||
      entityPersonDefault;

    setEntityType && setEntityType(type);
    setTimeout(() => {
      setField('dataType', type, form, context);
    }, 10);

    // }, [entityPersonDefault, entityOrigin, document, formRefreshed]); // eslint-disable-line react-hooks/exhaustive-deps
  }, [document]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEntityType &&
      setEntityType(selectedEntity?.dataType || entityPersonDefault);

    if (selectedEntity && selectable) {
      setField(
        'entityOrigin',
        selectable ? 'registered_entity' : 'new_entity',
        form,
        context,
      );
      setEntityOrigin('registered_entity');
    }
  }, [selectedEntity]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    const initialDocument = document || {};
    const selEntity =
      selectedEntity && selectedEntity?._id !== initialDocument?.[context]?._id
        ? selectedEntity || null
        : initialDocument?.[context]?.selectedEntity || null;

    let fields = {};

    if (entityOrigin === 'new_entity' && selectable) {
      fields = {
        ...initialDocument,
        ...form.getFieldsValue(),
        [context]: {
          entityOrigin,
        },
      };

      form.resetFields();
      setSelectedEntity && setSelectedEntity(null);
    } else {
      fields = {
        ...initialDocument,
        // ...form.getFieldsValue(),
        [context]: {
          ...document?.[context],
          ...selectedEntity,
          // ...form.getFieldsValue()[context],
          entityOrigin,
          selectedEntity: selEntity,
        },
      };

      setSelectedEntity && setSelectedEntity(document?.[context] || null);
    }

    setLoading && setLoading(true);
    setTimeout(() => {
      form.setFieldsValue(fields);
      setLoading && setLoading(false);
    }, 1000);
  }, [entityOrigin, document]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onChangeEntityOrigin = async ev => {
    const value = typeof ev === 'object' ? ev?.target?.value : ev;

    setEntityOrigin(value);
  };

  const onEntitySelect = async value => {
    const doc = await entityService.getEntityById(value.key);

    setSelectedEntity(doc || null);
    setEntityOrigin('registered_entity');
    setBillingEmailsOptions(entityService.getEntityAllEmailsArr(doc));

    const body = entityService.decorateEntity(doc, 1, context);

    form.setFieldsValue(body);
  };

  const onCpfCnpjChange = async ev => {
    let tagId = ev?.target?.id;
    let fieldName;

    if (tagId) {
      const parts = tagId.split('_');
      fieldName = parts && parts.length > 0 ? parts[parts.length - 1] : null;
    }

    const value = typeof ev === 'object' ? ev?.target?.value : ev;
    const cpfCnpj = returnOnlyNumbers(value);

    if (
      (entityPersonType === 'auto' && !isValidCpfCnpj(cpfCnpj)) ||
      (entityPersonType === 'company' && !isValidCnpj(cpfCnpj)) ||
      (entityPersonType === 'person' && !isValidCpf(cpfCnpj)) ||
      (entityPersonType === 'manual' &&
        entityPersonDefault === 'company' &&
        !isValidCnpj(cpfCnpj)) ||
      (entityPersonType === 'manual' &&
        entityPersonDefault === 'person' &&
        !isValidCpf(cpfCnpj))
    ) {
      return;
    }

    if (!isValidCpfCnpj(cpfCnpj)) return;

    if (cpfCnpj === document?.cpf || cpfCnpj === document?.cnpj) return;

    const doc = await entityService.getEntityByField(cpfCnpj);

    if (!doc) {
      setEntityType &&
        setEntityType(isValidCnpj(cpfCnpj) ? 'company' : 'person');
      return true;
    }

    let title = translateX(
      'document_exists',
      ['%CPF_CNPJ%'],
      [doc?.cpf ? 'CPF' : 'CNPJ'],
    );

    const options = {
      Content: translateX(
        'document_exists_description',
        ['%CPF_CNPJ%'],
        [doc?.cpf ? 'CPF' : 'CNPJ'],
      ),
      okText: 'yes',
      cancelText: 'no',

      onCancel: () => {
        if (!form) {
          console.warn(`no 'form' found on props!`);
          return;
        }

        if (context) {
          form.setFieldValue([context, fieldName], '');
        } else {
          form.setFieldValue(fieldName, '');
        }

        return false;
      },

      onOk: async () => {
        if (!form) {
          console.warn(`no 'form' found on props!`);
          return;
        }

        if (!selectable) {
          if (!selectedModule?.urlCustomForm) {
            const route = selectedModule?.routes.find(r =>
              r?.includes(selectedModule?.url),
            );

            if (route) {
              const newUrl = route.includes(':_id')
                ? route.replace(':_id', doc?._id || 'add')
                : `${route}/${doc?._id || 'add'}`;

              navigate(newUrl);
            }
          }

          refreshDocument(doc?._id, !!selectedModule?.urlCustomForm).then();
        } else {
          const value = clone(doc);
          value.key = doc._id;
          await onEntitySelect(value);
        }

        setEntityType && setEntityType(doc.dataType || 'company');
        if (fieldName && elRefs) {
          goFocus(elRefs, fieldName, 'all');
        }
        return false;
      },
    };

    appService.modal('q', title, options);

    return false;
  };

  const onChangeValidateRequired = field => {
    if (!phoneOrEmailRequired) {
      setIsPhoneRequired(
        requiredFields?.includes(context ? [context, 'phone'] : 'phone'),
      );
      setIsEmailRequired(
        requiredFields?.includes(context ? [context, 'email'] : 'email'),
      );

      return;
    }

    const phone = form?.getFieldValue(context ? [context, 'phone'] : 'phone');
    const isPhoneValid = isValidPhoneNumber(phone);
    const email = form?.getFieldValue(context ? [context, 'email'] : 'email');
    const isEmailValid = isValidEmail(email);

    setIsPhoneRequired(!isEmailValid);
    setIsEmailRequired(!isPhoneValid);

    if (isPhoneValid)
      form?.validateFields([context ? [context, 'email'] : 'email']);
    if (isEmailValid)
      form?.validateFields([context ? [context, 'phone'] : 'phone']);

    // setTimeout(()=>{
    goFocus(elRefs, [context ? [context, field] : field]);
    // },5000)
  };

  const onAddBusinessArea = () => {
    setSelectedRecord(null);
    setShowBusinessAreaModal(true);
  };

  const onBusinessAreaOrigin = record => {
    setSelectedRecord(record); // Passa o registro completo
    setShowBusinessAreaModal(true);
  };

  const onBlurName = () => {
    const name = getFieldName('name', context, document);
    const fullName = getFieldName('fullName', context, document);

    if (!fullName) {
      setField('fullName', name, form, context);
    }
  };

  // local variables III -------------------------------------------------------
  const isShowDataType = !selectable && entityPersonType === 'manual';
  const isRegisteredEntity = entityOrigin === 'registered_entity';
  const isEntityProfileAuto = entityPersonType === 'auto';
  const isEntityTypeCompany = entityType === 'company';
  const isEntityTypePerson = entityType === 'person';
  const isAdditionalInfoOptional = additionalInfo === 'optional';
  const isAdditionalInfoRequired = additionalInfo === 'required';
  const businessAreaOptions = buildBusinessAreaOptions();

  // UI COMPONENTS -------------------------------------------------------------
  const AdditionalInfo = (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={9} lg={12}>
          <JarvislyInput
            context={context}
            name="fullName"
            label={entityType === 'person' ? 'full_name' : 'company_name'}
          />
        </Col>

        <Col xs={24} sm={12} md={7} lg={6}>
          <JarvislyDatePicker
            context={context}
            name="birthdate"
            label={translateX(
              entityType === 'person' ? 'birthdate' : 'foundation_date',
            )}
          />
        </Col>

        <Col xs={24} sm={12} md={8} lg={6}>
          {entityType === 'person' ? (
            <JarvislySelectGender context={context} />
          ) : (
            <JarvislySelect
              label="area_activy"
              name="businessAreaId"
              options={businessAreaOptions}
              context={context}
              showSearch
              allowClear
              x-show-add-button={true}
              x-on-add-button-fn={onAddBusinessArea}
              optionLabelProp="text"
            />
          )}
        </Col>
      </Row>

      {/* ADDRESS */}
      <JarvislyAddressForm {...props} addressContext={context} />
      {/* ADDRESS */}
    </>
  );

  // local variables IV --------------------------------------------------------
  const customerAdditionalFields = buildCustomerAdditionalFields();

  // UI COMPONENTS -------------------------------------------------------------
  const Title = props => {
    return showTitle ? (
      <JarvislyTitle
        title={typeof showTitle === 'string' ? showTitle : 'personal_data'}
        {...props}
      />
    ) : null;
  };

  // UI COMPONENTS -------------------------------------------------------------
  const SelectedOrigin = props => {
    return (
      <Flex
        justify={isMobile ? 'center' : 'space-between'}
        align="center"
        vertical={isMobile}
        {...props}
      >
        {/* ENTITY ORIGIN */}
        <JarvislyRadioButtons
          context={context}
          name="entityOrigin"
          onChange={onChangeEntityOrigin}
          noLabel
          options={[
            {
              value: 'registered_entity',
              label: translateX(originRegisteredLabel),
            },
            {
              value: 'new_entity',
              label: translateX(originNewLabel),
            },
          ]}
        />
        {/* ENTITY ORIGIN */}

        {/* ENTITY PERSON TYPE */}
        {entityOrigin === 'new_entity' ? (
          entityPersonType === 'manual' ? (
            <Flex
              justify={isMobile ? 'center' : 'space-between'}
              align="center"
              vertical={isMobile}
            >
              <JarvislySegmented
                name="dataType"
                context={context}
                x-no-label
                options={segmentedOptions}
                onChange={v => setEntityType && setEntityType(v)}
                block={false}
              />
            </Flex>
          ) : null
        ) : null}
        {/* ENTITY PERSON TYPE */}
      </Flex>
    );
  };

  const NewEntityPerson = props => {
    return (
      <Row gutter={ROW_GUTTER} style={props.style || {}}>
        {/* CPF */}
        <Col
          xs={24}
          sm={9}
          lg={6}
          order={cpfCnpjFirst ? 1 : 2}
          style={{
            display: showCpfCnpj ? 'block' : 'none',
          }}
        >
          <JarvislyCpf
            context={context}
            label={cpfCnpjLabel}
            onChange={onCpfCnpjChange}
            required={true}
          />
        </Col>
        {/* CPF */}

        {/* NAME */}
        <Col xs={24} sm={15} lg={6} order={cpfCnpjFirst ? 2 : 1}>
          <JarvislyInput context={context} name="name" onBlur={onBlurName} />
        </Col>
        {/* NAME */}

        {/* PHONE */}
        <Col xs={24} sm={9} lg={6} order={3}>
          <JarvislyPhone
            context={context}
            required={isPhoneRequired}
            onChange={() => onChangeValidateRequired('phone')}
          />
        </Col>
        {/* PHONE */}

        {/* EMAIL */}
        <Col xs={24} sm={15} lg={6} order={4}>
          <JarvislyEmail
            context={context}
            required={isEmailRequired}
            onChange={() => onChangeValidateRequired('email')}
          />
        </Col>
        {/* EMAIL */}
      </Row>
    );
  };

  const NewEntityCompany = props => {
    return (
      <>
        <Row gutter={ROW_GUTTER} style={props.style || {}}>
          {/* CNPJ */}
          <Col
            xs={24}
            sm={10}
            lg={8}
            order={cpfCnpjFirst ? 1 : 2}
            style={{
              display: showCpfCnpj ? 'block' : 'none',
            }}
          >
            <JarvislyCnpj
              context={context}
              label={cpfCnpjLabel}
              onChange={onCpfCnpjChange}
            />
          </Col>
          {/* CNPJ */}

          {/* COMPANY */}
          <Col
            xs={24}
            sm={14}
            lg={showWebsite ? 8 : 16}
            order={cpfCnpjFirst ? 2 : 1}
          >
            <JarvislyInput context={context} name="name" label="company" />
          </Col>
          {/* COMPANY */}

          {/* WEBSITE */}
          {showWebsite ? (
            <Col xs={24} sm={24} lg={8} order={3}>
              <JarvislyWebsite context={context} />
            </Col>
          ) : null}
          {/* WEBSITE */}
        </Row>

        {/* CONTACT FORM */}
        <JarvislyContactForm
          {...props}
          showWebsite={false}
          showName
          context={context}
        />
        {/* CONTACT FORM */}
      </>
    );
  };

  const NewEntityCompanyOrPerson = props => {
    return (
      <Row gutter={ROW_GUTTER} style={props.style || {}}>
        {/* CPF/CNPJ */}
        <Col
          xs={24}
          sm={10}
          lg={8}
          order={cpfCnpjFirst ? 1 : 2}
          style={{
            display: showCpfCnpj ? 'block' : 'none',
          }}
        >
          <JarvislyCpfCnpj
            context={context}
            label={cpfCnpjLabel}
            onChange={onCpfCnpjChange}
          />
        </Col>
        {/* CPF/CNPJ */}

        {/* NAME OR COMPANY */}
        {entityOrigin === 'new_entity' ? (
          <Col
            xs={24}
            sm={14}
            lg={showWebsite ? 8 : 16}
            order={cpfCnpjFirst ? 2 : 1}
          >
            <JarvislyInput
              context={context}
              name="name"
              label={translateX(entityType === 'person' ? 'name' : 'company')}
            />
          </Col>
        ) : null}
        {/* NAME OR COMPANY */}

        {/* PERSON PHONE */}
        {entityOrigin === 'new_entity' && entityType === 'person' ? (
          <Col xs={24} sm={10} lg={showCpfCnpj ? 6 : 8} order={3}>
            <JarvislyPhone
              context={context}
              required={isPhoneRequired}
              onChange={() => onChangeValidateRequired('phone')}
            />
          </Col>
        ) : null}
        {/* PERSON PHONE */}

        {/* COMPANY WEBSITE OR PERSON EMAIL */}
        {entityOrigin === 'new_entity' ? (
          entityType === 'company' && showWebsite ? (
            <Col xs={24} sm={24} lg={showCpfCnpj ? 10 : 12} order={4}>
              <JarvislyWebsite context={context} />
            </Col>
          ) : entityType === 'person' ? (
            <Col xs={24} sm={24} lg={12} order={4}>
              <JarvislyEmail
                context={context}
                required={isEmailRequired}
                onChange={() => onChangeValidateRequired('email')}
              />
            </Col>
          ) : null
        ) : null}
        {/* COMPANY WEBSITE OR PERSON EMAIL */}
      </Row>
    );
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Title />

      <SelectedOrigin
        style={{
          display: selectable ? 'flex' : 'none',
        }}
      />

      <Row
        gutter={ROW_GUTTER}
        style={{
          display: isRegisteredEntity ? 'flex' : 'none',
        }}
      >
        {/* ENTITY */}
        <Col xs={24} md={12}>
          <JarvislyAsyncAutoComplete
            context={context}
            name="selectedEntity"
            label={searchEntityLabel}
            required={isRegisteredEntity}
            tooltip="Nome, Telefone, Email ou Documento"
            x-data-profile={completeDataProfile}
            x-on-select={onEntitySelect}
          />
        </Col>
        {/* ENTITY */}
      </Row>

      {/* NEW ENTITY */}

      <Flex
        justify={isMobile ? 'center' : 'space-between'}
        align="center"
        vertical={isMobile}
        style={{
          display: isShowDataType && !isRegisteredEntity ? 'flex' : 'none',
        }}
      >
        {/* ENTITY TYPE OPTIONS */}
        <JarvislySegmented
          name="dataType"
          context={context}
          x-no-label
          options={segmentedOptions}
          onChange={v => setEntityType && setEntityType(v)}
          block={false}
        />
        {/* ENTITY TYPE OPTIONS */}
      </Flex>

      {/* CPF OR CNPJ */}
      <NewEntityCompanyOrPerson
        {...props}
        style={{
          display: !isRegisteredEntity && isEntityProfileAuto ? 'flex' : 'none',
        }}
      />

      <NewEntityCompany
        {...props}
        style={{
          display:
            !isRegisteredEntity && !isEntityProfileAuto && isEntityTypeCompany
              ? 'flex'
              : 'none',
        }}
      />

      <NewEntityPerson
        {...props}
        style={{
          display:
            !isRegisteredEntity && !isEntityProfileAuto && isEntityTypePerson
              ? 'flex'
              : 'none',
        }}
      />

      {/*
      {!isRegisteredEntity ? (
        isEntityProfileAuto ? (
          <NewEntityCompanyOrPerson {...props} />
        ) : isEntityTypeCompany ? (
          <NewEntityCompany {...props} />
        ) : (
          <NewEntityPerson {...props} />
        )
      ) : null}
*/}
      {/* CPF OR CNPJ */}

      {/* ADDITIONAL FIELDS */}

      {!isRegisteredEntity ? (
        isAdditionalInfoOptional ? (
          <Collapse
            ghost
            items={customerAdditionalFields}
            className="no-collapse-padding"
          />
        ) : isAdditionalInfoRequired ? (
          customerAdditionalFields[0].children
        ) : null
      ) : null}

      {/* ADDITIONAL FIELDS */}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildSegmentedOptions() {
    return [
      {
        label: translateX('legal_person'),
        value: 'company',
        icon: <ShopOutlined />,
      },
      {
        label: translateX('individual_person'),
        value: 'person',
        icon: <UserOutlined />,
      },
    ];
  }

  function buildCpfCnpjLabel() {
    switch (entityPersonType) {
      case 'person':
        return 'cpf';

      case 'company':
        return 'cnpj';

      case 'manual':
        return translateX(entityType === 'company' ? 'cnpj' : 'cpf');

      case 'auto':
      default:
        return undefined;
    }
  }

  function buildBusinessAreaOptions() {
    if (!parameters?.entityParameters?.businessArea?.length) {
      return [];
    }

    return parameters?.entityParameters?.businessArea?.map(businessArea => {
      return {
        value: businessArea._id,
        text: businessArea.label,
        label: (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span key={businessArea._id}>
                <Badge
                  dot
                  style={{
                    marginRight: 8,
                    color: businessArea?.foreColor,
                    backgroundColor: businessArea?.backColor,
                    marginTop: 4,
                  }}
                />
                {businessArea.label}
              </span>

              {mode !== 'view' && (
                <Tooltip title={translateX('edit')}>
                  <Button
                    size="small"
                    className="m-2"
                    icon={<EditOutlined />}
                    onClick={() => onBusinessAreaOrigin(businessArea)} // Passando o registro completo
                  />
                </Tooltip>
              )}
            </div>
          </>
        ),
        disabled: false,
      };
    });
  }

  function buildCustomerAdditionalFields() {
    return [
      {
        key: 'additional_fields',
        label: `${translateX('additional_info')} (${translateX('optional')})`,
        // children: <h1>add fields</h1>,
        // children: <AdditionalInfo {...props} />,
        children: AdditionalInfo,
      },
    ];
  }
});

export default JarvislyEntityForm;

/*
const CUSTOMER = {
  __customer: {
    erro: false,
    addressTitle: 'Endereço Principal',
    city: 'Campinas',
    __city: 'Campinas - SP',
    province: 'SP',
    addressCoordinates: [-22.9859823, -47.1411576],
    addressAdditionalInfo: {
      note: 'até 311/312',
      areaCode: '19',
      giaCode: '2446',
      ibgeCode: '3509502',
      siafiCode: '6291',
    },
    addressMetadata: {
      countryCode: 'br',
      foundByApi: true,
      apiUrl: 'https://viacep.com.br/ws/13056174/json/',
      zipWithMultipleAddress: false,
    },
    number: '3',
    address1: 'Rua Antônio Rocha Batista',
    neighborhood: 'Jardim São Pedro de Viracopos',
    fullName: 'Condominio Residencial Costa Laguna',
    birthdate: '2021-01-11T03:00:00.000Z',
    businessAreaId: '671014c81aba1c58ed6c318d',
    zip: '13056-174',
    entityOrigin: 'new_entity',
    cnpj: '40888377000143',
    name: 'Costa Laguna',
    contactName: 'José Carlos',
    phone: '19 97403-3632',
    email: 'cond.costalaguna@gmail.com',
  },
  water_level_sensor: {
    tankShape: 'cylindrical',
    tankMaterial: 'concrete',
    depthSensor: 620,
    depthFlow: 600,
    width: 230,
    depthSensorSuffix: 'yearly',
    depthFlowSuffix: 'yearly',
    widthSuffix: 'yearly',
  },
  note: 'A torre dágua possui 4 células sendo que o dispositivo está instalado na célula superior.',
  channel: '917ADD7B',
  type: 'water_level_sensor',
  model: 'ultrasonic',
  color: 'rgba(193, 180, 1, 1)',
  title: 'Torre de Água',
  description: 'Célula Superior (Topo)',
};
*/
