import axiosService from './axiosService';
import { API_ENTITIES } from 'constants/ApiConstant';
import JarvislyEntityProfileTag from '../components/jarvisly-components/forms/Items/JarvislyEntityProfileTag';
import {
  clone,
  isCpfOrCnpj,
  isValidEmail,
  parseCnpjToDisplay,
  parseCpfToDisplay,
  returnOnlyNumbers,
} from '../utils/helpers';
import React from 'react';
import { ShopOutlined, UserOutlined } from '@ant-design/icons';
import { getPhoneProfileByNumber } from '../components/jarvisly-components/others/PhoneInput';
import dayjs from 'dayjs';

const ENTITIES_API = '/v1/entity';
const entityService = {};

entityService.getEntityByField = async (
  value,
  field = 'cpfCnpj',
  projection,
) => {
  const options = {
    returnAsObject: true,
    fullSearch: true,
    field: field,
    value: value,
    projection,
  };

  const [doc] = await entityService.getEntitiesByField(options);
  return doc;
};

entityService.getEntitiesByField = async options => {
  const {
    folder,
    dataType,
    dataProfile,
    field,
    value,
    projection, // Array of string (ex: ['name', 'birthdate', ...])
    returnAsObject = false,
    fullSearch = false, // false: use Regex to find by part of name, true: find 'full name' without regex.
  } = options;

  let url = `${ENTITIES_API}/get-by-field`;

  url += `/${folder || null}`;
  url += `/${dataType || null}`;
  url += `/${dataProfile || null}`;
  url += `/${field || null}`;
  url += `/${encodeURIComponent(value) || null}`;

  if (projection) url += `/${projection}`;

  const axiosOptions = {
    url,
    returnAsObject,
    headers: { 'x-fullsearch': fullSearch },
  };
  return await axiosService.get(axiosOptions);
};

entityService.getEntityById = async (_id, projection) => {
  let url = ENTITIES_API;

  if (projection) url += `/${projection}`;

  const axiosOptions = { url, _id };
  const [doc] = await axiosService.get(axiosOptions);
  return doc;
};

entityService.getEntitiesByPowerSearch = async options => {
  const {
    folder,
    dataType,
    dataProfile, // Array of string (ex: ['customer', 'provider', ...])
    value,
    projection, // Array of string (ex: ['name', 'birthdate', ...])
    returnAsObject = false,
    fullSearch = false, // false: use Regex to find by part of name, true: find 'full name' without regex.
  } = options;

  let url = `${ENTITIES_API}/get-by-power-search`;

  url += `/${folder || null}`;
  url += `/${dataType || null}`;
  url += `/${dataProfile || null}`;
  url += `/${value || null}`;

  if (projection) url += `/${projection}`;

  const axiosOptions = {
    url,
    returnAsObject,
    headers: { 'x-fullsearch': fullSearch },
  };
  return await axiosService.get(axiosOptions);
};

entityService.addEntity = async (data, profile) => {
  if ((!data?._id && !data?.dataType) || !profile) {
    return console.error('no dataType or dataProfile');
  }

  const cpfCnpj = data?.cpfCnpjNewCustomer || data?.cpf || data?.cnpj;
  const phoneProfile = getPhoneProfileByNumber(data.phone);

  const body = {
    ...data,
    uniqueKey: returnOnlyNumbers(cpfCnpj) || +new Date(),
    dataProfile: profile,
  };

  if (data.dataType === 'person') body.cpf = cpfCnpj;
  if (data.dataType === 'company') body.cnpj = cpfCnpj;

  if (data.phone && phoneProfile === 'mobile') body.mobile = data.phone;
  if (data.phone && phoneProfile !== 'mobile') body.phone = data.phone;

  const axiosOptions = {
    url: API_ENTITIES,
    returnAsObject: true,
    _id: data?._id,
    data: body,
  };
  const [doc] = await axiosService.upsert(axiosOptions);
  return doc;
};

entityService.upsertEntity = async (entityId, data, headers) => {
  delete data._id;
  delete data._metadata;

  const [doc] = entityId
    ? await axiosService.put({
        _id: entityId,
        url: ENTITIES_API,
        headers,
        data,
      })
    : await axiosService.post({
        url: ENTITIES_API,
        headers,
        data,
      });

  return doc;
};

entityService.addContact = async (entityId, data) => {};

entityService.removeContact = async (entityId, data) => {
  if (data.dataProfile.length === 1 && data.dataProfile.includes('contact')) {
    axiosService
      .delete({
        url: API_ENTITIES,
        _id: data.entityId,
        headers: { 'x-forcepermanentlydeletation': !data.cpf },
      })
      .then();
  }

  const url = `${API_ENTITIES}/add-to-set/${entityId}/$pull/moreContacts.entityId`;
  return await axiosService.put({
    url,
    data: { _id: data.entityId },
  });
};

entityService.getEntityTypeByCpfCnpj = cpfCnpj => {
  const type = isCpfOrCnpj(cpfCnpj);
  return type === 'cnpj' ? 'company' : type === 'cpf' ? 'person' : null;
};

entityService.buildSelectedEntity = (
  entity,
  xFieldId = '_id',
  xFieldName = 'name',
  xFieldFullName = 'fullName',
) => {
  return {
    key: entity?.[xFieldId],
    // value: entity?.[xFieldName],
    value: entity?.[xFieldFullName],
    title: entity?.[xFieldFullName],

    label: (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span className="text-uppercase font-weight-bold">
            {entity.dataType === 'company' ? (
              <ShopOutlined className="padding-1" />
            ) : (
              <UserOutlined className="padding-1" />
            )}
            {/*{entity?.[xFieldName]}*/}
            {entity?.[xFieldFullName]}
          </span>

          <span>
            <JarvislyEntityProfileTag record={entity} space="left" />
          </span>
        </div>

        <div>
          <span className="text-muted text-uppercase">
            ID: {entity?._metadata?.__documentCode}
          </span>

          {entity?.cpf || entity?.cnpj ? (
            <span>
              {' '}
              |&nbsp;
              {entity?.cpf
                ? parseCpfToDisplay(entity.cpf)
                : parseCnpjToDisplay(entity?.cnpj)}
            </span>
          ) : null}

          {entity.__city ? <span> | {entity.__city}</span> : null}
        </div>
      </>
    ),
  };
};

entityService.getEntityAllEmailsArr = entity => {
  const customerEmails = [];

  if (entity?.billingEmails) customerEmails.push(...entity.billingEmails);
  if (entity?.email) customerEmails.push(entity.email);
  if (entity?.moreContacts?.length > 0)
    customerEmails.push(...entity.moreContacts.map(c => c.email));

  return [...new Set([...customerEmails.filter(e => isValidEmail(e))])];
};

entityService.getBillingEmails = entity => {
  return entity?.billingEmails?.length > 0
    ? entity?.billingEmails
    : entity?.email
      ? [entity?.email]
      : [];
};

entityService.decorateEntity = (
  entity,
  level = 1,
  context = null,
  dataProfile,
) => {
  const e = clone(entity);

  if (!e?.cpfCnpj) {
    e.cpfCnpj = e?.cpfCnpjNewCustomer || e.cpf || e.cnpj;
  }

  if (!e?.dataType) {
    e.dataType = entityService.getEntityTypeByCpfCnpj(e.cpfCnpj);
  }

  if (!e?.dataProfile) {
    e.dataProfile = dataProfile;
  }

  if (e?.cpfCnpj && !e?.cpf && !e?.cnpj) {
    if (e.dataType === 'person') {
      e.cpf = e.cpfCnpj;
    } else if (e.dataType === 'company') {
      e.cnpj = e.cpfCnpj;
    }
  }

  const level1 = {
    _id: e?._id,

    entityOrigin: e?._id ? 'registered_entity' : 'new_entity',
    selectedEntity: entityService.buildSelectedEntity(e),
    name: e?.name || e.selectedEntity?.name,
    fullName: e?.fullName || e.selectedEntity?.fullName,
    cpfCnpj: e?.cpf || e?.cnpj,
    cpfCnpjSelectedEntity: e?.cpf || e?.cnpj,
    cpf: e?.cpf,
    cnpj: e?.cnpj,
    mobileDialCode: e?.mobileDialCode,
    mobile: e?.mobile || e?.phone || null,
    note: e?.note,
    billingEmails: entityService.getBillingEmails(e),
    phoneDialCode: e?.phoneDialCode,
    phone: e?.phone,
    email: e?.email,
    gender: e?.gender,
    birthdate: e?.birthdate,
    businessAreaId: e.businessAreaId,

    zip: e?.zip,
    address1: e?.address1,
    number: e?.number,
    address2: e?.address2 || null,
    __city: e?.__city,
    addressTitle: e?.addressTitle,
    neighborhood: e?.neighborhood,
    city: e?.city,
    province: e?.province,
    addressCoordinates: e?.addressCoordinates,
    addressAdditionalInfo: e?.addressAdditionalInfo,
    addressMetadata: e?.addressMetadata,
  };

  const level2 = {
    uniqueKey: e?.uniqueKey,
    dataProfile: e?.dataProfile,
    dataType: e?.dataType,
  };

  const level3 = {
    _metadata: e?._metadata,
    backColor: e?.backColor,
    foreColor: e?.foreColor,
    attachmentIds: e?.attachmentIds,
    status: e?.status,
    moreContacts: e?.moreContacts,
    moreAddress: e?.moreAddress,
    lastName: e?.lastName,
    firstName: e?.firstName,
    parameters: e?.parameters,
    _integrations: e?._integrations,
  };

  let data = { ...level1 };
  if (level >= 2) data = { ...data, ...level2 };
  if (level >= 3) data = { ...data, ...level3 };

  if (data?.birthdate) data.birthdate = dayjs(data.birthdate);

  return context ? { [context]: data } : data;
};

entityService.persistEntity = async (
  body,
  entityContext,
  selectedEntity,
  entityType,
  dataProfile,
  document,
) => {
  // if (body?.[entityContext]?.dataType === 'company') {

  if (body?.[entityContext]?.contactName) {
    const moreContacts =
      selectedEntity && document?.[entityContext]?.moreContacts
        ? document[entityContext].moreContacts
        : body?.[entityContext]?.moreContacts || [];

    const contact = {
      name: body?.[entityContext]?.contactName,
      mobile: body?.[entityContext]?.phone || '',
      email: body?.[entityContext]?.email || '',
      dataType: 'person',
      dataProfile: ['contact'],
      note: body?.[entityContext]?.note || '',
      mainContact: true,
    };

    const idx = moreContacts.findIndex(
      c => c.mobile === contact.mobile || c.email === contact.email,
    );

    if (idx > -1) {
      // Atualize os dados do contato existente
      moreContacts[idx] = { ...moreContacts[idx], ...contact };
    } else {
      // Crie um novo contato
      const axiosOptions = {
        url: API_ENTITIES,
        data: contact,
      };

      const [entityContact] = await axiosService.post(axiosOptions);

      moreContacts.push({ entityId: entityContact._id, note: contact.note });
    }

    body[entityContext].moreContacts = moreContacts;
  }

  if (body?.[entityContext]) {
    body[entityContext].dataType = entityType;
    body[entityContext]._id = body[entityContext]?.selectedEntity?.key;

    const entity = entityService.decorateEntity(
      {
        ...selectedEntity,
        ...body[entityContext],
        _id: selectedEntity?._id || body?.[entityContext]?.selectedEntity?.key,
      },
      3,
      null,
      dataProfile,
    );

    // Adicione a verificação do retorno da função upsertEntity
    return await entityService.upsertEntity(entity._id, entity);
  } else {
    console.error('Body does not contain [entityContext]');
    return {};
  }
};

export default entityService;
