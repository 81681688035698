// COMPONENT *******************************************************************
// *****************************************************************************
import { Tabs } from 'antd';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { translateX } from 'utils/helpers';
// import CompaniesRecordIndex from '../../../appmulti/operators/Form/Record/RecordIndex';
import { JarvislyFormProvider, JarvislyTabProvider } from 'components/jarvisly-components/others/FormComponents';
import OrdersRecordIndex from './Record/RecordIndex';
import entityService from 'services/entityService';

const OrdersForm = props => {
  // providers context ---------------------------------------------------------
  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, mode } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  // const url = useUrl();
  const initTab = selectedModule.documentState.initTab;
  // const availableTabs = [initTab, 'contacts', 'parameters'];

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [step, setStep] = useState(0);
  const [enabledSteps, setEnabledSteps] = useState([0]);

  const [plan, setPlan] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [updating, setUpdating] = useState(null);
  const [order, setOrder] = useState(null);
  const [, setAdminAdjustment] = useState(null);
  const [, setAdminDiscount] = useState(null);
  const [emergencyContacts, setEmergencyContacts] = useState(null);

  const [paymentSelected, setPaymentSelected] = useState(null);
  const [creditCardInstallment, setCreditCardInstallment] = useState('1X');

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedCustomer,
    setSelectedCustomer,
    ...props,
    step,
    setStep,
    setPlan,
    plan,
    setEnabledSteps,
    enabledSteps,
    isGenerating,
    setIsGenerating,
    setUpdating,
    updating,
    order,
    setOrder,
    setAdminAdjustment,
    setAdminDiscount,

    emergencyContacts,
    setEmergencyContacts,

    paymentSelected,
    setPaymentSelected,
    creditCardInstallment,
    setCreditCardInstallment,
  };

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;
    let customer = entityService.decorateEntity(
      {
        ...selectedCustomer,
        ...body.customer,
        _id: selectedCustomer?._id || body?.customer?.selectedEntity?.key,
      },
      3,
      null,
      'customer',
    );
    console.log('2',customer)

    customer = await entityService.upsertEntity(customer?._id, customer);
    console.log('3',customer)

    return {
      buyerId: customer?._id,
    }
  };

  // local variables III -------------------------------------------------------
  const tabItems = buildTabItens();

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-orders"
      document={document}
      autoFocus="name"
      requiredFields={['name']}
      showSubmitButton={tab !== 'contacts'}
      setTab={setTab}
      onSave={onSave}
    >
      <Tabs
        type="line"
        tabPosition="top"
        activeKey={tab}
        rootClassName="tab-in-form"
        items={tabItems.filter(x => !x.hide)}
        onTabClick={onTabClick}
      />
    </JarvislyFormProvider>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('record'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <OrdersRecordIndex {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },

      {
        label: translateX('contacts'),
        key: 'contacts',
        hide: false,
        disabled: mode === 'add',
        children: <h1>TAB2</h1>,
      },
    ];
  }
};

export default OrdersForm;
